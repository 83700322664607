import { useMutation } from "@tanstack/react-query"
import type { UserPreferenceParam } from "../../data/types/UserSetting"
import { AuthService } from "../../services/AuthService"
import { getErrorMessage, getResponseMessage } from "../../utils/handleHttpResponseUtils"
import { useAppContext } from "../../context/login/hooks/AppContextProvider"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../context/login/components/organisms/Protected/UserLogin"

export function useUserPreferenceMutation() {
  const { error, } = useAppContext() ?? {}
  const { fetchAndSetUserInfo } = useAuth()
  const { t } = useTranslation()

  return useMutation({
    mutationFn: (body: UserPreferenceParam) =>
      AuthService.putUserPreference(body),
    onSuccess(data) {
      const message = getResponseMessage(data)
      if (message) {
        error.setMessage(message)
        return null
      }
      if (data.status === 200) {
        // window.location.href = '/'
        // refetch user info once user info is updated
        fetchAndSetUserInfo()
      }
      return null
    },
    onError(err) {
      const message = getErrorMessage(err)
      if (message) {
        error.setMessage(t(message))
      }
    },
  })
}