import { useQuery, useQueryClient } from "@tanstack/react-query"
import { FETCH_USER_LOCATION_AFTER_FIRST_TIME, FETCH_USER_LOCATION_AFTER_MINUTE } from "../../appConstants"
import { useAuth } from "../../context/login/components/organisms/Protected/UserLogin"
import { useIsCorrectMapCoordinatesResponse } from "./useMapCoordinates";
import { DisasterService } from "../../services/disasterRiskManagement/DisasterRiskManagementService";
import { refetchLatestDisasterInterval } from "../../pages/newDashboard/common/refetchLatestDisaster";
import { useEffect, useRef, useState } from "react";

export function useUserLocationsQuery() {
  const { currentTenantId } = useAuth();
  const queryClient = useQueryClient();  
  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()
  const [enabled, setEnabled] = useState(false)

  const { data: latestDisasterStatus } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
    refetchInterval: refetchLatestDisasterInterval,
  })

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  // make this enabled after some time, not immediately
  useEffect(() => {
    let timeoutId: null | ReturnType<typeof setTimeout> = null
    const shouldEnable = !!disasterId && !!currentTenantId

    // make enabled after creating a disaster
    timeoutId = setTimeout(function () {
      setEnabled(shouldEnable)
    }, FETCH_USER_LOCATION_AFTER_FIRST_TIME * 60 * 1000)

    // cleanup
    return function () {
      clearTimeout(timeoutId)
      setEnabled(false)
    }
  }, [currentTenantId, disasterId])

  return useQuery({
    queryKey: ['user-location', currentTenantId],
    enabled,
    queryFn: () => DisasterService.fetchUserLocations(currentTenantId),
    refetchInterval(_data, query) {
      const msInMin = 60 * 1000
      return FETCH_USER_LOCATION_AFTER_MINUTE * msInMin
    },
    onSuccess(response) {
      const disasterIdFromLocation = response.data.disaster_id
      // check location APi response
      // if `disaster_id` from locations API does not match current disaster id, refetch latest API
      if (disasterIdFromLocation && disasterId && (disasterIdFromLocation != disasterId)) {
        queryClient.invalidateQueries({
          queryKey: ['disaster-status', currentTenantId],
        })
      }
    },
  })
}
