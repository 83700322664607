import i18next from 'i18next'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../context/login/components/organisms/Protected/UserLogin'
import { localStorageUtils } from '../utils/localStorageUtils'
import { useUserPreferenceMutation } from './mutation/useUserPreferenceMutation'

/**
 * useHeaderState
 * Display Logo, Userid, langugage, user-preference and logout
 * OnClick of language and user preference dropdown and allow user to update
 *
 * @returns
 */

export const useHeaderState = () => {
  const { t } = useTranslation()
  const {
    activeTenantId,
    currentTenantId,
    tenantsList,
    activeTenantData,
    userInfoData,
  } = useAuth()

  localStorageUtils.setUserLanguage('ja')
  const [isVisibleLanguage, setIsVisibleLanguage] = useState<boolean>(false)
  const getUserLanguage: string | '' = localStorageUtils.getUserLanguage()
  const [userLanguage, setUserLanguage] = useState<string>(getUserLanguage)
  const tenantListOptions = tenantsList ?? []
  const userTenantDisplayName = activeTenantData?.label || ''
  const userName = userInfoData?.user_attribute?.user_name || ''
  const userEmail = userInfoData?.email || ''

  // toggle language dropdown
  const toggleLanguageDropdown = () => {
    setIsVisibleLanguage(!isVisibleLanguage)
  }

  //change language on selection language from dropdown
  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang)
    localStorageUtils.setUserLanguage(lang)
  }

  // Mutation function to update userpreference data on selecting tenant from header
  const handleTenantSwitch = useUserPreferenceMutation()

  React.useEffect(() => {
    if (userLanguage) {
      changeLanguage(userLanguage)
    }
  }, [userLanguage])

  return {
    toggleLanguageDropdown,
    changeLanguage,
    userTenantDisplayName,
    tenantListOptions,
    activeTenantId,
    currentTenantId,
    userName,
    userEmail,
    handleTenantSwitch,
  }
}
