// AuthContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import { AuthService } from '../../../../../services/AuthService'
import {
  UserInfoData,
  UserInfoTenants,
} from '../../../../../data/types/UserInfo'
import {
  UserPreference,
  UserPreferenceParam,
} from '../../../../../data/types/UserSetting'
import { AxiosResponse } from 'axios'
import { useCurrentRoute, useNavigation } from 'react-navi'
import NewSpinner from '../../../../../components/common/NewSpinner'
import { useRedirect } from '../../../../../hooks/auth/useRedirect'
import { localStorageUtils } from '../../../../../utils/localStorageUtils'
import {
  getErrorMessage,
  getResponseMessage,
} from '../../../../../utils/handleHttpResponseUtils'
import { useAppContext } from '../../../hooks/AppContextProvider'
import {
  publicRoutesConstant,
  roleNames,
  routesConstant,
  userConstants,
} from '../../../../../appConstants'
import { OptionType } from '../../../../../data/types/OptionType'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../translations/i18n'
import { removeCookie, setCookie } from '../../../../../utils/cookie'

interface AuthContextProps {
  fetchingRefreshToken: boolean
  fetchingUserInfo: boolean
  setFetchingUserInfo: React.Dispatch<React.SetStateAction<boolean>>
  role: any
  userInfoData: UserInfoData | null
  setUserInfoData: (value: React.SetStateAction<UserInfoData | null>) => void
  activeTenantId: string
  setActiveTenantId: React.Dispatch<React.SetStateAction<string>>
  currentTenantId: string
  isDashboardVisible: boolean
  updateUserPreference: (
    userPrefParam: UserPreferenceParam
  ) => Promise<AxiosResponse<UserPreference, any> | undefined>
  tenantsList: OptionType[] | undefined
  activeTenantData: OptionType | undefined
  fetchAndSetUserInfo: () => Promise<void>
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

// Fetch all user info and set in local storage and cookies
const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [userInfoData, setUserInfoData] = useState<UserInfoData | null>(null)
  const [activeTenantId, setActiveTenantId] = useState<string>('') // the current selected tenant in the tenants dropdown
  const [fetchingRefreshToken, setFetchingRefreshToken] = useState(true)
  const [fetchingUserInfo, setFetchingUserInfo] = useState(true)
  const { error } = useAppContext() ?? {}
  const setMessage = error.setMessage
  const { t } = useTranslation()

  const pathname = useCurrentRoute().url.pathname
  const publicFacingURL = publicRoutesConstant.includes(pathname)
  const search = useCurrentRoute()?.url.search

  useRedirect()
  const navigation = useNavigation()

  const {
    tenantsList,
    activeTenantData,
    activeTenantRolesArr,
  } = useMemo(() => {
    const tenantsList: OptionType[] | undefined = userInfoData?.tenants.map(
      (tenant: UserInfoTenants) => ({
        label: tenant.name,
        value: tenant.id,
      })
    )

    // find which tenant id to be default selected
    const activeTenantData = tenantsList?.find(
      (tenant) =>
        tenant.value ===
        `${userInfoData?.user_attribute?.user_preference || ''}`
    )

    // find and set role of the default selected tenant id
    const activeTenantRolesArr = userInfoData?.tenants.find(
      (tenant: UserInfoTenants) => tenant.id === `${activeTenantData?.value}`
    )?.envs[0]?.roles

    return { tenantsList, activeTenantData, activeTenantRolesArr }
  }, [userInfoData?.tenants, userInfoData?.user_attribute?.user_preference])

  const activeRole = useMemo(() => {
    // find the role to be given to user
    // (in order of priority -> app_admin > security_guard > general_user)
    return (
      activeTenantRolesArr?.find(
        (role: any) => role.role_name === roleNames.app_admin
      )?.role_name ||
      activeTenantRolesArr?.find(
        (role: any) => role.role_name === roleNames.security_guard
      )?.role_name ||
      activeTenantRolesArr?.find(
        (role: any) => role.role_name === roleNames.general_user
      )?.role_name
    )
  }, [activeTenantRolesArr])

  const isDashboardVisible =
    !!activeTenantData && activeRole === roleNames.app_admin
  const currentTenantId = userInfoData?.user_attribute?.user_preference || ''
  const role = activeRole || activeTenantRolesArr?.[0]?.role_name

  // call credential API when there is a query param named 'code' in URL
  useEffect(() => {
    // Fetch Refresh Token and Access Token (called Id Token)
    const callCredentialsAPI = async () => {
      const code = new URLSearchParams(search).get('code')
      if (code) {
        try {
          const res = await AuthService.fetchCredentialsCode(code)
          const idToken = res.data.id_token
          if (!idToken) {
            throw new Error('ID token not found in response')
          }
          localStorage.setItem('SaaSusIdToken', idToken)
          const refreshToken = res.data.refresh_token
          if (!refreshToken) {
            throw new Error('Refresh token not found in response')
          }
          setCookie('SaaSusRefreshToken', refreshToken)
          setFetchingRefreshToken(false)
        } catch (error) {
          console.error('Error in getToken function:', error)
        }
      }
      setFetchingRefreshToken(false)
    }

    callCredentialsAPI()
  }, [search])

  const fetchAndSetUserInfo = useCallback(async () => {
    try {
      const jwtToken = localStorageUtils.getIdToken()
      if (!jwtToken) return // Exit if no token found

      setFetchingUserInfo(true)

      removeCookie('idToken')

      // call user-info api and set the data on every initial load
      const res = await AuthService.fetchUserInfo()
      let userDataResponse = {
        ...res?.data,
      }
      const message = getResponseMessage(res)
      if (message) {
        setMessage(message)
        return
      }

      let usersPreferredTenant =
        userDataResponse?.user_attribute?.user_preference
      const firstTenantId = userDataResponse?.tenants?.[0]?.id
      const tenantsListResponse: OptionType[] = userDataResponse?.tenants.map(
        (tenant: UserInfoTenants) => ({
          label: tenant.name,
          value: tenant.id,
        })
      ) ?? []

      
      const preferredTenantNotInList = usersPreferredTenant && firstTenantId && !tenantsListResponse?.some(tenant => tenant.value === usersPreferredTenant)

      if (
        // if user's preferred tenant empty
        // or if user's preferred tenant is present and it is not in list of tenants
        (!usersPreferredTenant && firstTenantId) ||
        preferredTenantNotInList
      ) {
        //   i. update user's preferred tenant as the first tenant from tenants array
        //   ii. refetch user info and the newly updated tenant should now show as user's preferred tenant automatically (since updated using BE API)
        await AuthService.putUserPreference({
          user_preference: firstTenantId,
        })

        // call user info API again
        const newUserDataResponse = await AuthService.fetchUserInfo()
        const newUserData = newUserDataResponse?.data

        // reset user's preferred tenant and user data
        userDataResponse = newUserData
        usersPreferredTenant =
          userDataResponse?.user_attribute?.user_preference
      }

      setUserInfoData({ ...userDataResponse })

      const tenantsList_: OptionType[] =
        userDataResponse?.tenants.map((tenant: UserInfoTenants) => ({
          label: tenant.name,
          value: tenant.id,
        })) ?? []

      // find which tenant id to be default selected
      const activeTenantData = tenantsList_.find(
        (tenant) => tenant.value === `${usersPreferredTenant || ''}`
      )
      setActiveTenantId(activeTenantData?.value || '')
    } catch (err) {
      const message = getErrorMessage(err)
      setUserInfoData(null)
      if (message) {
        setMessage(i18n.t(message))
      }
    } finally {
      setFetchingUserInfo(false)
    }
  }, [setMessage])

  React.useEffect(() => {
    // No need to fetch user info API
    // on a public facing route
    if (publicFacingURL) {
      return
    }
    ;fetchAndSetUserInfo()
  }, [fetchAndSetUserInfo, publicFacingURL])

  // redirect to qr code page by default if user has security guard access
  React.useEffect(() => {
    if (activeRole === roleNames.security_guard) {
      navigation.navigate(routesConstant.APPLICATION_QR_CODE)
    }
  }, [activeRole, navigation])

  // @TODO: Need to remove the code once all the functionality is complete
  const updateUserPreference = useCallback(
    async (userPrefParam: UserPreferenceParam) => {
      try {
        const res = await AuthService.putUserPreference(userPrefParam)
        return res
      } catch (error) {
        console.error('Error updating user preference:', error)
      }
    },
    []
  )

  if (fetchingRefreshToken) {
    return (
      <div>
        <NewSpinner />
      </div>
    )
  }

  return (
    <AuthContext.Provider
      value={{
        role,
        fetchingRefreshToken,
        fetchingUserInfo,
        setFetchingUserInfo,
        userInfoData,
        setUserInfoData,
        activeTenantId,
        setActiveTenantId,
        currentTenantId,
        isDashboardVisible,
        updateUserPreference,
        tenantsList,
        activeTenantData,
        fetchAndSetUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }

export function isGuestUser(userInfo: UserInfoData | null) {
  return userInfo?.user_type === userConstants.GUEST
}
