import React, { useEffect, useReducer, useRef } from 'react'

import iconMenuLogo from '../../assets/images/icn-menu.svg'
import iconArrowDownGreyLogo from '../../assets/images/icn-arrow-down-grey.svg'
import iconFireHydrantLogo from '../../assets/images/icn-fire-hydrant.svg'
import {
  alertType,
  disasterType,
  FETCH_PREVENTION_ITEMS_AFTER_MINUTES,
  mapZoomConfig,
  powerPlantPreventionItemsSettingConstant,
  roleNames,
  routesConstant,
} from '../../appConstants'

const {
  DEFAULT_DISASTER_LOCATION,
  LOCAL_COMMAND_HQ_VEHICLE,
  EMERGENCY_SHOWER,
  AED,
  AUXILIARY_FOAM_FIRE_STOPPER_65,
  DISASTER_MATERIALS_EQUIPMENT,
  EXTINGUISHING_OUTDOOR_HYDRANT,
  HANDSET,
  OUTDOOR_FIRE_HYDRANT_50,
  OUTDOOR_FIRE_HYDRANT_65,
  PROTECTIVE_EQUIPMENT,
  SANDBAG,
} = powerPlantPreventionItemsSettingConstant

const { minZoomForDoubleClick } = mapZoomConfig

// @TODO: move to constants
type PreventionItem = keyof typeof powerPlantPreventionItemsSettingConstant
const dropdowns = ['SHOW_HIDE', 'REGISTER'] as const
type Dropdown = typeof dropdowns[number]

import AEDImg from '../../assets/images/AED_.png'
import HANDSETImg from '../../assets/images/HS.png'
import SANDBOX from '../../assets/images/SAND.png'
import VEHICLE from '../../assets/images/FCVehicle.png'
import OFH50 from '../../assets/images/EOH5.5.png'
import OFH65 from '../../assets/images/EOH6.5.png'
import EOH75 from '../../assets/images/EOH7.5.png'
import SHOWER from '../../assets/images/shower.png'
import AFFS from '../../assets/images/AFFS6.5.png'
import DME from '../../assets/images/DPE.png'
import DPE from '../../assets/images/PE.png'
import cancel from '../../assets/images/icons/cir.svg'
import iconFire from '../../assets/images/fireIcon.svg'
import iconAccident from '../../assets/images/icons/icn-danger-disaster.svg'
import { Trans, useTranslation } from 'react-i18next'
import { isGuestUser, useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import type { PutDisasterPlaceParam } from '../../services/disasterRiskManagement/DisasterRiskManagementService'

import type {
  Marker,
  Map as MapLibreMap,
  Popup,
  MapLayerMouseEvent,
} from 'maplibre-gl'
import MapLibreGL from 'maplibre-gl'
import i18n from '../../translations/i18n'
import ConfirmBoxModal from '../../components/common/ConfirmBoxModal'
import { RegisterDisasterItemsPayload } from '../../data/types/DisasterRiskManagement'
import useLocalStorageUserData from '../../useLocalStorageUserData'
import { SetState } from '../../data/types/common'
import { useAppContext } from '../../context/login/hooks/AppContextProvider'
import { reFetchHazardAreaInterval } from './common/refetchHazardArea'
import UIkit from 'uikit'
import { useNavigation } from 'react-navi'
import { useIsCorrectMapCoordinatesResponse, useMapCoordinates } from '../../hooks/queries/useMapCoordinates'
import { refetchLatestDisasterInterval } from './common/refetchLatestDisaster'
import { useUserLocationsQuery } from '../../hooks/queries/useUserLocationsQuery'

// get images
const iconMap: Record<string, string> = {
  EXTINGUISHING_OUTDOOR_HYDRANT: EOH75,
  OUTDOOR_FIRE_HYDRANT_65: OFH65,
  OUTDOOR_FIRE_HYDRANT_50: OFH50,
  AUXILIARY_FOAM_FIRE_STOPPER_65: AFFS,
  AED: AEDImg,
  SANDBAG: SANDBOX,
  DISASTER_MATERIALS_EQUIPMENT: DME,
  PROTECTIVE_EQUIPMENT: DPE,
  HANDSET: HANDSETImg,
  LOCAL_COMMAND_HQ_VEHICLE: VEHICLE,
  EMERGENCY_SHOWER: SHOWER,
}

const nonDisasterShowHideItems = [
  EXTINGUISHING_OUTDOOR_HYDRANT,
  OUTDOOR_FIRE_HYDRANT_65,
  OUTDOOR_FIRE_HYDRANT_50,
  AUXILIARY_FOAM_FIRE_STOPPER_65,
  AED,
  SANDBAG,
  DISASTER_MATERIALS_EQUIPMENT,
  PROTECTIVE_EQUIPMENT,
  HANDSET,
  LOCAL_COMMAND_HQ_VEHICLE,
  EMERGENCY_SHOWER,
]

const nonDisasterRegisterItems = [
  EXTINGUISHING_OUTDOOR_HYDRANT,
  OUTDOOR_FIRE_HYDRANT_65,
  OUTDOOR_FIRE_HYDRANT_50,
  AUXILIARY_FOAM_FIRE_STOPPER_65,
  AED,
  SANDBAG,
  DISASTER_MATERIALS_EQUIPMENT,
  PROTECTIVE_EQUIPMENT,
  HANDSET,
]

const disasterShowHideItems = [
  EXTINGUISHING_OUTDOOR_HYDRANT,
  OUTDOOR_FIRE_HYDRANT_65,
  OUTDOOR_FIRE_HYDRANT_50,
  AUXILIARY_FOAM_FIRE_STOPPER_65,
  AED,
  SANDBAG,
  DISASTER_MATERIALS_EQUIPMENT,
  PROTECTIVE_EQUIPMENT,
  HANDSET,
  LOCAL_COMMAND_HQ_VEHICLE,
  EMERGENCY_SHOWER,
]

const disasterRegisterItems = [
  DEFAULT_DISASTER_LOCATION,
  LOCAL_COMMAND_HQ_VEHICLE,
  EMERGENCY_SHOWER,
]

// get default disabled items
// based on dropdown type and whether disaster is present
const getDisabledItems = (
  isDisaster: boolean,
  name: Dropdown,
  hasMarker: boolean
): PreventionItem[] => {
  if (name === 'REGISTER') {
    return isDisaster && hasMarker
      ? []
      : isDisaster && !hasMarker
      ? [DEFAULT_DISASTER_LOCATION, LOCAL_COMMAND_HQ_VEHICLE, EMERGENCY_SHOWER]
      : []
  }
  if (name === 'SHOW_HIDE') {
    const defaultDisabledItems = [LOCAL_COMMAND_HQ_VEHICLE, EMERGENCY_SHOWER]
    if (!isDisaster) {
      return defaultDisabledItems
    }

    if (isDisaster && !hasMarker) {
      return defaultDisabledItems
    }

    return []
  }

  throw new Error('getDisabledItems: unknown dropdown type passed!')
}

function makeMenuItemsArray({
  existingItems,
  hasDisaster,
  dropdown,
  hasMarker,
}: {
  existingItems: MenuItem[]
  hasDisaster: boolean
  dropdown: Dropdown
  hasMarker: boolean
}) {
  const getAllItems = (
    isDisaster: boolean,
    name: Dropdown
  ): PreventionItem[] => {
    if (name === 'REGISTER') {
      return isDisaster ? disasterRegisterItems : nonDisasterRegisterItems
    }
    if (name === 'SHOW_HIDE') {
      return isDisaster ? disasterShowHideItems : nonDisasterShowHideItems
    }

    throw new Error('makeMenuItemsArray: unknown dropdown type passed!')
  }

  // if has disaster
  //

  const newArr = getAllItems(hasDisaster, dropdown)
  return newArr.map((item) => {
    // if "car or shower items are selected" and disaster not present, do not re-select it
    const noDisasterAndDisasterBasedAlreadySelected =
      hasDisaster ||
      ![LOCAL_COMMAND_HQ_VEHICLE, EMERGENCY_SHOWER].includes(item as any)

    const alreadySelected = existingItems.find(
      (existingItem) => existingItem.key === item
    )?.selected

    const selected =
      (alreadySelected && noDisasterAndDisasterBasedAlreadySelected) ||
      // if there is disaster, by default select disaster location
      (hasDisaster && item === DEFAULT_DISASTER_LOCATION)

    return {
      selected: !!selected,
      key: item,
      disabled: getDisabledItems(hasDisaster, dropdown, hasMarker).includes(
        item
      ),
      // disabledDuringSubmit: false,
    }
  })
}

type MenuItem = {
  selected: boolean
  key: PreventionItem
  disabled: boolean
  // disabledDuringSubmit: boolean
}

type MenuState = {
  showHideItems: MenuItem[]
  registrationItems: MenuItem[]
  selectedShowHideItems: MenuItem[]
  selectedRegistrationItems: MenuItem[]
  deleteDisasterItem: {
    showModal: boolean
    disasterItemId: null | number
    disasterItemKey: null | string
  }
  registerDisasterPlaceConfirmModal: {
    show: boolean
    type: PreventionItem
  } | null
  registerCoordinates: { lat: number; lng: number } | null
}

type ACTIONS =
  | 'RECALCULATE_ITEMS'
  | 'TOGGLE_SHOW_HIDE_MENU_ITEM'
  | 'TOGGLE_REGISTER_ITEMS'
  | 'UPDATE_STATE'
  // | 'SET_SELECTED_SHOW_HIDE_ITEMS'
  // | 'SET_SELECTED_REGISTER_ITEMS'
  | 'SET_SELECTED_REGISTER_ITEMS_AND_SHOW_HIDE_ITEMS'
  | 'RESET_TEMPORARY_CHECKED_ITEMS'

type Payload = {
  hasDisaster?: boolean
  item?: PreventionItem
  newState?: Partial<MenuState>
  dropdown?: Dropdown
  hasMarker?: boolean
}

function reducer(
  current: MenuState,
  action: { type: ACTIONS; payload: Payload }
): MenuState {
  const { type, payload } = action

  // set default (preset) items
  if (type === 'RECALCULATE_ITEMS') {
    if (payload.hasDisaster === undefined || payload.hasMarker === undefined) {
      throw new Error('hasDisaster not provided!')
    }
    return {
      ...current,
      registrationItems: makeMenuItemsArray({
        dropdown: 'REGISTER',
        existingItems: current.registrationItems,
        hasDisaster: payload.hasDisaster,
        hasMarker: payload.hasMarker,
      }),
      showHideItems: makeMenuItemsArray({
        dropdown: 'SHOW_HIDE',
        existingItems: current.showHideItems,
        hasDisaster: payload.hasDisaster,
        hasMarker: payload.hasMarker,
      }),
      selectedShowHideItems: makeMenuItemsArray({
        dropdown: 'SHOW_HIDE',
        existingItems: current.selectedShowHideItems,
        hasDisaster: payload.hasDisaster,
        hasMarker: payload.hasMarker,
      }),
      selectedRegistrationItems: makeMenuItemsArray({
        dropdown: 'REGISTER',
        existingItems: current.selectedRegistrationItems,
        hasDisaster: payload.hasDisaster,
        hasMarker: payload.hasMarker,
      }),
    }
  }

  if (type === 'TOGGLE_SHOW_HIDE_MENU_ITEM') {
    if (!payload.item) {
      throw new Error('params missing for toggling menu items - hide/show')
    }
    return {
      ...current,
      // multiselect behavior in case of show / hide items
      showHideItems: current.showHideItems.map((item) => {
        return {
          ...item,
          selected: item.key === payload.item ? !item.selected : item.selected,
        }
      }),
    }
  }

  if (type === 'TOGGLE_REGISTER_ITEMS') {
    if (!payload.item) {
      throw new Error('params missing for toggling menu items - register')
    }
    return {
      ...current,
      // single select behavior for register items
      registrationItems: current.registrationItems.map((item) => {
        const isCurrentItem = item.key === payload.item
        const selected = item.selected
        const nextSelected = isCurrentItem ? !item.selected : false
        return {
          ...item,
          // once a register item is selected,
          // should not allow to unselect that specific item
          selected: isCurrentItem && selected ? selected : nextSelected,
        }
      }),
    }
  }

  // update "selected" register items and show/hide items when submit button is clicked
  // these "selected" show/hide items then will be shown on map
  if (type === 'SET_SELECTED_REGISTER_ITEMS_AND_SHOW_HIDE_ITEMS') {
    const { hasDisaster, hasMarker } = payload

    if (hasDisaster === undefined || hasMarker === undefined) {
      throw new Error(
        'All variables not provided while submitting register items'
      )
    }

    // on click of submit, the items in register dropdowns should be auto selected in show/hide dropdown and
    // those auto-selected items in show/hide dropdown should be made disabled
    const newShowHideItems = current.showHideItems.map((item) => {
      // check if this is one of selected items in registered items
      const itemToBeRegistered = current.registrationItems.some(
        (regItem) => regItem.key === item.key && regItem.selected
      )

      return {
        ...item,
        selected: item.selected || itemToBeRegistered,
        disabled:
          itemToBeRegistered ||
          // enable again if it was selected when submit button was clicked
          getDisabledItems(hasDisaster, 'SHOW_HIDE', hasMarker).includes(
            item.key
          ),
      }
    })
    return {
      ...current,
      selectedRegistrationItems: current.registrationItems,
      selectedShowHideItems: newShowHideItems,
      // also update  local checked state for show/hide items
      showHideItems: newShowHideItems,
    }
  }

  if (type === 'RESET_TEMPORARY_CHECKED_ITEMS') {
    if (payload.hasDisaster === undefined || payload.hasMarker === undefined) {
      throw new Error('hasDisaster, hasMarker not provided!')
    }

    return {
      ...current,
      // override with 'selected' registration item (shown in map)
      registrationItems: current.registrationItems.map((item) => {
        return {
          ...item,
          selected: current.selectedRegistrationItems.some(
            (selectedItem) =>
              selectedItem.selected && selectedItem.key === item.key
          ),
        }
      }),
      // override with 'selected' show/hide item (shown in map)
      showHideItems: current.showHideItems.map((item) => {
        return {
          ...item,
          selected: current.selectedShowHideItems.some(
            (selectedItem) =>
              selectedItem.selected && selectedItem.key === item.key
          ),
        }
      }),
    }
  }

  if (type === 'UPDATE_STATE') {
    if (payload.newState === undefined) {
      throw new Error('Unexpected undefined new state to set')
    }
    return {
      ...current,
      ...payload.newState,
    }
  }

  throw new Error(`unhandled action, ${action}`)
}

const DisasterPreventionItemsControlMenu = ({
  mapObject,
  preventionItemsToggle,
  setPreventionItemsToggle,
  setPreventionItemsToggleDisabled,
  preventionItemsToggleDisabled,
}: {
  mapObject?: MapLibreMap | null
  preventionItemsToggle?: boolean
  preventionItemsToggleDisabled?: boolean
  setPreventionItemsToggle?: SetState<boolean>
  setPreventionItemsToggleDisabled?: SetState<boolean>
}) => {
  const { t } = useTranslation()
  const navigation = useNavigation()
  const { role, currentTenantId } = useAuth()
  const { app_admin } = roleNames
  const { alert } = useAppContext()
  const { setAlertMessage } = alert

  //  check if current route is same as qr code page
  const isQRCodePage =
    window.location.pathname === routesConstant.APPLICATION_QR_CODE

  const [state, dispatch] = useReducer(reducer, {
    // local state for checkbox selection
    showHideItems: [],
    registrationItems: [],
    // states to set when submit button is clicked
    selectedShowHideItems: [],
    selectedRegistrationItems: [],
    // delete disaster items state
    deleteDisasterItem: {
      showModal: false,
      disasterItemId: null,
      disasterItemKey: null,
    },
    registerDisasterPlaceConfirmModal: null,
    registerCoordinates: null,
  })

  const menuOpenRef = useRef(false)
  const preventionItemsMenuRef = useRef(null)
  const mapInitialZoonRef = useRef<number | null>(null)

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  // latest disaster info
  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterStatusLoading,
    error: latestDisasterStatusError,
  } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && !isQRCodePage && (role === app_admin) && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
    refetchInterval: refetchLatestDisasterInterval,
  })

  const hasDisaster = !!latestDisasterStatus?.data.status
  const disaster_type = latestDisasterStatus?.data.disaster_type

  // get all disaster (register) items
  // (except command vehicle, shower)
  const {
    data: disasterItemsResponse,
    isLoading: disasterItemsLoading,
    error: disasterItemsError,
  } = useQuery({
    queryKey: ['disaster-items', currentTenantId],
    enabled: !!currentTenantId && !isQRCodePage && (role === app_admin) && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchPreventionItems(currentTenantId),
  })

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  const {
    data: disasterAreaResponse,
    isLoading: disasterAreaLoading,
    error: disasterAreaError,
  } = useQuery({
    queryKey: ['disaster-area', currentTenantId, disasterId],
    enabled: !!currentTenantId && !!disasterId,
    queryFn: () =>
      DisasterService.fetchDisasterArea(currentTenantId, disasterId as number),
    refetchInterval: reFetchHazardAreaInterval,
  })

  // if hazard area marker should show on map
  const hasMarker =
    !!disasterAreaResponse?.data.status &&
    !!disasterAreaResponse?.data.hazard_point.geometry

  // show and car items fetching
  const {
    data: registerItemsResponse,
    isLoading: registerItemsLoading,
    error: registerItemsError,
  } = useQuery({
    queryKey: ['disaster-register-items', currentTenantId],
    enabled: !!currentTenantId && !!disasterId && !!hasDisaster && !!hasMarker,
    queryFn: () =>
      DisasterService.fetchDisasterBasePreventionItems(
        currentTenantId,
        Number(disasterId)
      ),
    // refetch shower and car items after every 5 minutes if there is a disaster
    refetchInterval: hasDisaster
      ? FETCH_PREVENTION_ITEMS_AFTER_MINUTES * 60 * 1000
      : undefined,
  })

  const disasterPlaceMutation = useMutation({
    mutationFn: (
      body: PutDisasterPlaceParam & {
        tenantId: string
        disasterId: number
        status: boolean
      }
    ) =>
      DisasterService.putDisasterPlace(body.tenantId, body.disasterId, {
        latitude: body.latitude,
        longitude: body.longitude,
        status: body.status,
      }),
    onSuccess(response, variables, context) {
      if (!(mapObject && state.registerCoordinates)) {
        throw new Error('adding marker to map - variables not defined!')
      }

      // hide confirm modal and clear coordinates from last double click
      hideRegisterConfirmModal()

      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })
      // refetch disaster Area (to refresh data and redraw disaster marker)
      queryClient.invalidateQueries({
        queryKey: ['disaster-area', currentTenantId, disasterId],
      })

      // show success disaster toast
      if (hasMarker) {
        setAlertMessage({
          type: alertType.SUCCESS,
          message: t('TITLES.DISASTER_LOCATION_UPDATING_ALERT'),
        })
      } else {
        setAlertMessage({
          type: alertType.SUCCESS,
          message: t('TITLES.DISASTER_LOCATION_REGISTERATION_ALERT'),
        })
      }
    },
  })

  const deleteDisasterItemMutation = useMutation({
    mutationFn: ({
      disasterItemId,
      type,
    }: {
      disasterItemId: number
      type: string
    }) =>
      DisasterService.deletePreventionItems(currentTenantId, disasterItemId),
    onSuccess(data, variables, context) {
      const type = variables.type

      setAlertMessage({
        type: alertType.SUCCESS,
        message: `${t(`TITLES.${type}`)}${t(
          'TITLES.POWER_PLANT_BASE_PREVENTION_ITEMS_DELETION_SUCCESS'
        )}`,
      })

      queryClient.invalidateQueries({
        queryKey: ['disaster-items', currentTenantId],
      })
    },
  })

  // only for shower, car
  const deleteDisasterRegisterMutation = useMutation({
    mutationFn: ({
      disasterItemId,
      type,
    }: {
      disasterItemId: number
      type: string
    }) =>
      DisasterService.deletePreventionBasedItems(
        currentTenantId,
        disasterItemId,
        Number(disasterId)
      ),
    onSuccess(data, variables, context) {
      const type = variables.type

      setAlertMessage({
        type: alertType.SUCCESS,
        message: `${t(`TITLES.${type}`)}${t(
          'TITLES.POWER_PLANT_BASE_PREVENTION_ITEMS_DELETION_SUCCESS'
        )}`.trim(),
      })
      // refetch markers for shower and command car
      queryClient.invalidateQueries({
        queryKey: ['disaster-register-items', currentTenantId],
      })
    },
  })

  // for posting shower, car items
  const registerDisasterBasedItemsMutation = useMutation({
    mutationKey: ['mutation-disaster-register-items'],
    mutationFn: async (
      body: RegisterDisasterItemsPayload & {
        tenantId: string
        disasterId: number
      }
    ) => {
      return DisasterService.postPreventionsItemsDisasterBased(
        body.tenantId,
        body.disasterId,
        {
          disaster_item_type: body.disaster_item_type,
          latitude: body.latitude,
          longitude: body.longitude,
        }
      )
    },
    async onMutate(variables) {
      // new disaster item (to optimistically add to list of current items of given type)
      const newDisasterItem = {
        disaster_item_id: +new Date(),
        latitude: variables.latitude,
        longitude: variables.longitude,
      }
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ['disaster-register-items', currentTenantId],
      })

      // Snapshot the previous value
      const prevRegisterDisasterItems = queryClient.getQueryData([
        'disaster-register-items',
        currentTenantId,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['disaster-register-items', currentTenantId],
        (old: any) => {
          const newType = variables.disaster_item_type
          const oldDisasterItems = old?.data?.disaster_items || []
          const newDisasterItems = oldDisasterItems.map((item: any) => {
            if (item.disaster_item_type === newType) {
              return {
                ...item,
                disaster_items_list: [
                  ...(item.disaster_items_list || []),
                  newDisasterItem,
                ],
              }
            }
            return item
          })

          // API does not returns all disaster items keys by default
          // hence checking if present for optimistic updates
          const isRegisterDisasterItemAlreadyPresent = oldDisasterItems.some(
            (item: any) => item.type === newType
          )

          return {
            ...(old ?? {}),
            data: {
              ...(old?.data ?? {}),
              disaster_items: isRegisterDisasterItemAlreadyPresent
                ? newDisasterItems
                : [
                    ...oldDisasterItems,
                    {
                      disaster_item_type: newType,
                      disaster_items_list: [newDisasterItem],
                    },
                  ],
            },
          }
        }
      )

      // Return a context object with the snapshotted value
      return { prevRegisterDisasterItems }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['disaster-register-items', currentTenantId],
        context?.prevRegisterDisasterItems
      )
    },
    onSuccess(data, variables, context) {
      const type = variables.disaster_item_type

      setAlertMessage({
        type: alertType.SUCCESS,
        message: `${t(`TITLES.${type}`)}${t(
          'TITLES.REGISTERATION_ITEMS_SUCCESS'
        )}`,
      })
    },
    // Always refetch after error or success:
    onSettled: () => {
      // refetch markers for shower and command car
      queryClient.invalidateQueries({
        queryKey: ['disaster-register-items', currentTenantId],
      })
    },
  })

  const registerPowerplantRegisterItemsMutation = useMutation({
    mutationFn: (
      body: RegisterDisasterItemsPayload & {
        tenantId: string
      }
    ) =>
      DisasterService.postPreventionsItems(body.tenantId, {
        disaster_item_type: body.disaster_item_type,
        latitude: body.latitude,
        longitude: body.longitude,
      }),
    onSuccess(data, variables, context) {
      const type = variables.disaster_item_type

      setAlertMessage({
        type: alertType.SUCCESS,
        message: `${t(`TITLES.${type}`)}${t(
          'TITLES.REGISTERATION_ITEMS_SUCCESS'
        )}`,
      })

      // refetch markers for power plant based register items
      queryClient.invalidateQueries({
        queryKey: ['disaster-items', currentTenantId],
      })
    },
  })

  // postPreventionsItems

  const queryClient = useQueryClient()
  const [idx, reRenderSandwichMenu] = useReducer((i) => i + 1, 0) // for force re-rendering sandwichMenu
  const [sandwichMenuState, updateSandwichMenuStateState] = useReducer(
    sandwichMenuReducer,
    {
      wholeMenuOpen: false,
      hideShowSubMenuOpen: false,
      registerSubMenuOpen: false,
    }
  )

  const disasterItems = disasterItemsResponse?.data.disaster_items
  const registerItems = registerItemsResponse?.data.disaster_items // shower and car

  useEffect(() => {
    dispatch({
      type: 'RECALCULATE_ITEMS',
      payload: { hasDisaster, hasMarker: hasMarker },
    })
  }, [hasMarker, hasDisaster])

  // take all the selected show / hide items and show them into map
  useEffect(() => {
    const showVehicleAndShowerMarkers = hasDisaster && hasMarker
    const combinedItems = [
      ...(disasterItems ?? []),
      ...((showVehicleAndShowerMarkers ? registerItems : []) ?? []), // response for command vehicle marker and shower marker
    ]

    if (!(combinedItems.length && mapObject && role && preventionItemsToggle)) {
      return
    }

    const markers: Marker[] = []
    const popups: Popup[] = []

    const controller = new AbortController()

    function onZoom() {
      if (!mapObject) {
        return
      }
      const currentZoom = getRoundedZoom(mapObject.getZoom())
      // show show/hide items and prevention items
      // only when certain zoom level is reached
      if (currentZoom >= minZoomForDoubleClick) {
        // filter disaster items response to those that are selected in show/hide
        const filteredDisasterItems = combinedItems
          .filter((disasterItem) => {
            return state.selectedShowHideItems.some(
              (selectedShowHideItem) =>
                selectedShowHideItem.selected &&
                selectedShowHideItem.key === disasterItem.disaster_item_type
            )
          })
          // don't show these items for some reason
          .filter((disasterItems) => {
            return (
              // disasterItems.disaster_item_type !== LOCAL_COMMAND_HQ_VEHICLE &&
              // disasterItems.disaster_item_type !== EMERGENCY_SHOWER &&
              disasterItems.disaster_item_type !== DEFAULT_DISASTER_LOCATION
            )
          })

        filteredDisasterItems.forEach((disasterItem) => {
          const { disaster_item_type, disaster_items_list } = disasterItem

          disaster_items_list.forEach((disaster_item) => {
            const iconUrl = iconMap[disaster_item_type]
            const { latitude, longitude, disaster_item_id } = disaster_item

            const htmlMarkerElement = getPreventionItemsIcon({
              handleDelete: () => {
                // show delete modal
                dispatch({
                  type: 'UPDATE_STATE',
                  payload: {
                    newState: {
                      deleteDisasterItem: {
                        disasterItemId: disaster_item_id,
                        showModal: true,
                        disasterItemKey: disaster_item_type,
                      },
                    },
                  },
                })
              },
              iconUrl: `url(${iconUrl})`,
              userRole: role,
            })
            htmlMarkerElement.setAttribute('data-icon-name', disaster_item_type)
            htmlMarkerElement.setAttribute(
              'data-icon-disaster-id',
              '' + disaster_item_id
            )

            // add popup
            let popup: Popup | null = null

            htmlMarkerElement.addEventListener(
              'mouseenter',
              () => {
                const coordinates: any = [longitude, latitude]
                const iconName = i18n.t(`TITLES.${disaster_item_type}`)
                popup = new MapLibreGL.Popup({
                  closeButton: false,
                  closeOnClick: false,
                  className: 'custom-items-popup',
                })
                popup.setLngLat(coordinates).setHTML(iconName).addTo(mapObject)
                popups.push(popup)
              },
              { signal: controller.signal }
            )

            htmlMarkerElement.addEventListener(
              'mouseleave',
              () => {
                // remove popup
                popup?.remove()
              },
              { signal: controller.signal }
            )

            const marker = new MapLibreGL.Marker({
              element: htmlMarkerElement,
            })
              .setLngLat([longitude, latitude])
              .addTo(mapObject)

            markers.push(marker)
          })
        })
      } else {
        markers.forEach((marker) => marker.remove())
        popups.forEach((marker) => marker.remove())
      }
    }

    // call function initially to account for toggle state change
    // this is fine since we are only showing markers when there is enough zoom level
    onZoom()

    // call function again on zoom change
    mapObject.on('zoom', onZoom)

    // cleanup
    return function () {
      // remove all markers
      markers.forEach((marker) => marker.remove())

      // remove all popups
      popups.forEach((marker) => marker.remove())

      // cleanup events
      controller.abort()

      mapObject.off('zoom', onZoom)
    }
  }, [
    disasterItems,
    mapObject,
    registerItems,
    role,
    state.selectedShowHideItems,
    preventionItemsToggle,
    hasDisaster,
    hasMarker,
  ])

  // handle map double click
  useEffect(() => {
    function handleMapDblClick(e: MapLayerMouseEvent) {
      if (!mapObject) {
        // do nothing
        return
      }

      const registerItemOptions = state.selectedRegistrationItems
      const registrationItemsSelected = registerItemOptions.filter(
        (item) => item.selected
      )

      if (registrationItemsSelected.length === 0) {
        // no registration items selected
        return
      }

      if (registrationItemsSelected.length > 1) {
        // single select, should only have one item here, ever
        throw new Error('got more than 1 selected, skipping')
      }

      const itemType = registrationItemsSelected[0].key
      const { lat, lng } = e.lngLat

      const mapZoom = getRoundedZoom(mapObject.getZoom())
      const defaultDisasterLocationSelected =
        itemType === DEFAULT_DISASTER_LOCATION

      // show warning for min map zoom level before registering
      if (
        mapZoom < minZoomForDoubleClick &&
        // only show zoom warning only when user has not selected default disaster location
        !defaultDisasterLocationSelected
      ) {
        setAlertMessage({
          type: alertType.WARNING,
          message: `${i18n.t(`TITLES.${itemType}`)}${i18n.t(
            'TITLES.ZOOM_LEVEL_ALERT'
          )}`,
        })
        return
      }

      // if already zoomed enough and prevention items toggle is off
      if (
        (mapZoom >= minZoomForDoubleClick) &&
        // only show zoom warning only when user has not selected default disaster location
        !defaultDisasterLocationSelected &&
        !preventionItemsToggle
      ) {
        setAlertMessage({
          type: alertType.WARNING,
          message: `${i18n.t(`TITLES.TURN_ON_TOGGLE`)}`,
        })
        return
      }

      // show confirm modal only when there is a disaster
      // and registering disaster marker
      const showConfirmModal = hasDisaster && defaultDisasterLocationSelected

      if (showConfirmModal) {
        // - show alert (whether updating / new place registering)
        dispatch({
          type: 'UPDATE_STATE',
          payload: {
            newState: {
              registerDisasterPlaceConfirmModal: {
                show: true,
                type: itemType,
              },
              registerCoordinates: { lat, lng },
            },
          },
        })
        return
      }

      if (!currentTenantId) {
        throw new Error('tenant id not found!')
      }

      // call disaster-items API for power plant register items
      if (!hasDisaster) {
        registerPowerplantRegisterItemsMutation.mutate({
          disaster_item_type: itemType,
          latitude: lat,
          longitude: lng,
          tenantId: currentTenantId,
        })
        // hide confirm modal
        return
      }

      if (!disasterId) {
        throw new Error('disaster id not found')
      }

      if (
        [LOCAL_COMMAND_HQ_VEHICLE, EMERGENCY_SHOWER].includes(
          itemType as any
        ) &&
        hasDisaster &&
        hasMarker
      ) {
        // for registering marker location for car and shower
        registerDisasterBasedItemsMutation.mutate({
          disaster_item_type: itemType,
          disasterId,
          latitude: lat,
          longitude: lng,
          tenantId: currentTenantId,
        })
        return
      }
    }

    mapObject?.on('dblclick', handleMapDblClick)
    return () => {
      mapObject?.off('dblclick', handleMapDblClick)
    }
  }, [
    mapObject,
    state.selectedRegistrationItems,
    setAlertMessage,
    preventionItemsToggle,
    hasDisaster,
    currentTenantId,
    registerPowerplantRegisterItemsMutation,
    disasterId,
    hasMarker,
    registerDisasterBasedItemsMutation,
  ])

  function closeMenu() {
    const refCurrent = preventionItemsMenuRef.current
    if (refCurrent) {
      UIkit.dropdown(refCurrent, { duration: 150 }).hide()
    }
  }

  const latestZoom = useRef<number | null>(null)
  // disable and turn of toggle when map is zoomed out completely
  useEffect(() => {
    if (!mapObject) {
      return
    }

    function onLoad() {
      if (mapObject) {
        const zoom = getRoundedZoom(mapObject.getZoom())
        mapInitialZoonRef.current = zoom
        latestZoom.current = zoom
      }
    }
    mapObject.on('load', onLoad)
    function onZoom() {
      if (!mapObject) {
        return
      }
      const currentZoom = getRoundedZoom(mapObject.getZoom())

      // if zooming in and enabled, don't disable it
      const zoomingIn = latestZoom.current && currentZoom > latestZoom.current
      if (zoomingIn && !preventionItemsToggleDisabled) {
        latestZoom.current = getRoundedZoom(mapObject.getZoom())
        return
      }

      const registerItemOptions = state.selectedRegistrationItems
      const showHideOptions = state.selectedShowHideItems
      const registrationItemsSelected = registerItemOptions.filter(
        (item) => item.selected && item.key !== DEFAULT_DISASTER_LOCATION
      )

      const hideShowItemsSelection = showHideOptions.filter(
        (item) => item.selected
      )

      if (
        currentZoom >= mapZoomConfig.minZoomForDoubleClick &&
        // if no register or hide/show items selected, do not enable toggle when zoomed in
        (registrationItemsSelected.length > 0 ||
          hideShowItemsSelection.length > 0)
      ) {
        // enable toggle when zoomed in a certain value
        setPreventionItemsToggleDisabled?.(false)
      }

      if (currentZoom < mapZoomConfig.minZoomForDoubleClick) {
        // enable toggle when zoomed in a certain value
        setPreventionItemsToggleDisabled?.(true)
        setPreventionItemsToggle?.(false)
      }
    }
    mapObject.on('zoomend', onZoom)

    // cleanup
    return function () {
      if (!mapObject) {
        return
      }
      mapObject.off('zoomend', onZoom)
      mapObject.off('load', onLoad)
    }
  }, [
    mapObject,
    setPreventionItemsToggleDisabled,
    setPreventionItemsToggle,
    preventionItemsToggleDisabled,
    state.selectedRegistrationItems,
    state.selectedShowHideItems,
  ])

  const {
    showModal: showDisasterItemDeleteConfirmModal,
    disasterItemId: deleteDisasterItemId,
    disasterItemKey: deleteDisasterItemKey,
  } = state.deleteDisasterItem

  const hideAndResetConfirmDisasterDeleteModal = () =>
    dispatch({
      type: 'UPDATE_STATE',
      payload: {
        newState: {
          deleteDisasterItem: {
            disasterItemId: null,
            showModal: false,
            disasterItemKey: null,
          },
        },
      },
    })

  function hideRegisterConfirmModal() {
    return dispatch({
      type: 'UPDATE_STATE',
      payload: {
        newState: {
          registerDisasterPlaceConfirmModal: null,
        },
      },
    })
  }

  function hideSandwichMenu() {
    // const sandwichMenuToggle = document.querySelector('.prevention-items-toggle') as HTMLElement;
    // sandwichMenuToggle.click();
    reRenderSandwichMenu() // re-render sandwich menu to close any opened dropdowns inside
    menuOpenRef.current = false
  }

  // redirect to QR code page
  const handleQRCodePageRedirect = () => {
    navigation.navigate(routesConstant.APPLICATION_QR_CODE)
  }

  const trainingCheckbox =
    hasDisaster && latestDisasterStatus?.data?.practice_flag

  return (
    <li className="uk-active uk-position-relative uk-menu-icn">
      {state.registerDisasterPlaceConfirmModal?.show && (
        <ConfirmBoxModal
          noAction={hideRegisterConfirmModal}
          yesAction={() => {
            const registerCoordinates = state.registerCoordinates
            const type = state.registerDisasterPlaceConfirmModal?.type

            if (!(currentTenantId && registerCoordinates && type)) {
              throw new Error('incomplete params!')
            }

            if (!disasterId) {
              throw new Error('disaster id not found!')
            }

            // hide confirm modal
            hideRegisterConfirmModal()

            // if default register location selected, then register disaster
            if (type === DEFAULT_DISASTER_LOCATION) {
              // call disaster place API
              disasterPlaceMutation.mutate({
                disasterId: disasterId,
                tenantId: currentTenantId,
                status: true,
                latitude: registerCoordinates.lat,
                longitude: registerCoordinates.lng,
              })
              return
            }
          }}
          messageTitle={
            hasMarker
              ? trainingCheckbox
                ? t(
                    'TITLES.DISASTER_PRACTICE_LOCATION_UPDATE_CONFIRMATION_TITLE'
                  )
                : t('TITLES.DISASTER_LOCATION_UPDATE_CONFIRMATION_TITLE')
              : trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_LOCATION_CONFIRMATION_TITLES')
              : t('TITLES.DISASTER_LOCATION_CONFIRMATION_TITLES')
          }
          messageBody={
            hasMarker
              ? t('TITLES.DISASTER_LOCATION_UPDATE_CONFIRMATION')
              : trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_LOCATION_CONFIRMATION_CONTENT')
              : t('TITLES.DISASTER_ACTUAL_LOCATION_CONFIRMATION_CONTENT')
          }
        />
      )}
      {showDisasterItemDeleteConfirmModal && deleteDisasterItemKey ? (
        <ConfirmBoxModal
          data-id="disasterRegistrationModal"
          noAction={hideAndResetConfirmDisasterDeleteModal}
          yesAction={() => {
            // call delete disaster Item API
            const id = deleteDisasterItemId

            if (!id) {
              throw new Error('no disaster item id found for deleting!')
            }

            if (
              [LOCAL_COMMAND_HQ_VEHICLE, EMERGENCY_SHOWER].includes(
                deleteDisasterItemKey as any
              )
            ) {
              // separate delete api for deleting shower, vehicle marker
              deleteDisasterRegisterMutation.mutate({
                disasterItemId: id,
                type: deleteDisasterItemKey,
              })
            } else {
              deleteDisasterItemMutation.mutate({
                disasterItemId: id,
                type: deleteDisasterItemKey,
              })
            }

            hideAndResetConfirmDisasterDeleteModal()
          }}
          messageBody={`${t(`TITLES.${deleteDisasterItemKey.trim()}`)}${t(
            'TITLES.PREVENTION_ITEMS_DELETION_CONTENT'
          )}`.trim()}
        />
      ) : null}
      <a
        aria-expanded="true"
        className="uk-padding-small-top prevention-items-toggle"
        onClick={() => {
          // reset sub menu states when toggle clicked
          updateSandwichMenuStateState({
            hideShowSubMenuOpen: false,
            registerSubMenuOpen: false,
          })
          // remove temporary items selected that were checked
          // after clicking on submit button
          dispatch({
            type: 'RESET_TEMPORARY_CHECKED_ITEMS',
            payload: {
              hasDisaster,
              hasMarker: hasMarker,
            },
          })
        }}
      >
        <img src={iconMenuLogo} className="icn-menu" />
      </a>
      <div
        className={`uk-light uk-arrow uk-arrow-top-right menu-icn custom-dropdown hamburger-dropdown`}
        id="prevention-items-dropdown"
        data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
        ref={preventionItemsMenuRef}
        key={idx}
      >
        <ul
          className="uk-nav-default"
          // data-uk-nav
          // hidden
        >
          {role === app_admin && !isQRCodePage && (
            <>
              <li
                className={`uk-parent uk-margin-small-bottom ${
                  sandwichMenuState.hideShowSubMenuOpen ? 'uk-open' : ''
                }`}
              >
                <a
                  className="color-white font-23 selected-bg"
                  onClick={() => {
                    updateSandwichMenuStateState({
                      hideShowSubMenuOpen: !sandwichMenuState.hideShowSubMenuOpen,
                      registerSubMenuOpen: false,
                    })
                  }}
                >
                  <Trans>TITLES.SHOW_HIDE_FACILITY_ICONS</Trans>
                  <img
                    src={iconArrowDownGreyLogo}
                    className="uk-margin-auto-left arrow-rotetion icn-down-arrow"
                    alt="icn-arrow-down"
                  />
                </a>
                <ul
                  className={`uk-nav-sub max-h-300 uk-overflow-auto`}
                  hidden={!sandwichMenuState.hideShowSubMenuOpen}
                >
                  {state.showHideItems.map((item) => {
                    const key = item.key
                    const name = t(`TITLES.${key}`)
                    const nameForLabel = `show-hide-${key}`
                    const disabled = item.disabled

                    return (
                      <li
                        className={`mt-5 ${disabled ? 'disabled' : ''}`}
                        key={key}
                      >
                        <a
                          className={`uk-flex uk-flex-middle default-cursor ${
                            disabled ? 'cursor-not-allowed' : 'default-cursor'
                          }`}
                        >
                          <div className="uk-flex uk-flex-middle column-gap-20 label-container flex-grow-1">
                            <img
                              src={iconMap[key]}
                              width="60"
                              height="60"
                              alt={name}
                            />
                            <label
                              htmlFor={nameForLabel}
                              className={`color-white font-25 flex-grow-1 ${
                                disabled
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            >
                              {name}
                            </label>
                          </div>
                          <div className="uk-margin-auto-left">
                            <input
                              className="uk-radio"
                              type="radio"
                              id={nameForLabel}
                              disabled={disabled}
                              readOnly
                              checked={item.selected}
                              onClick={() => {
                                dispatch({
                                  type: 'TOGGLE_SHOW_HIDE_MENU_ITEM',
                                  payload: {
                                    item: key,
                                  },
                                })
                              }}
                            />
                          </div>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </li>
              <li
                className={`uk-parent uk-margin-small-bottom ${
                  sandwichMenuState.registerSubMenuOpen ? 'uk-open' : ''
                }`}
              >
                <a
                  className="color-white font-23 selected-bg"
                  onClick={() => {
                    updateSandwichMenuStateState({
                      registerSubMenuOpen: !sandwichMenuState.registerSubMenuOpen,
                      hideShowSubMenuOpen: false,
                    })
                  }}
                >
                  <Trans>TITLES.REGISTER_ITEMS_SETTINGS</Trans>
                  <img
                    src={iconArrowDownGreyLogo}
                    className="uk-margin-auto-left arrow-rotetion icn-down-arrow"
                    alt="icn-arrow-down"
                  />
                </a>
                <ul
                  className={`uk-nav-sub max-h-300 uk-overflow-auto`}
                  hidden={!sandwichMenuState.registerSubMenuOpen}
                >
                  {state.registrationItems.map((item, index) => {
                    const key = item.key
                    const name = t(`TITLES.${key}`)
                    const nameForLabel = `register-${key}`
                    const disabled = item.disabled
                    const selected = item.selected
                    const defaultDisasterLocation =
                      key === DEFAULT_DISASTER_LOCATION

                    return (
                      <li
                        className={`${disabled ? 'disabled' : ''} ${
                          selected ? 'select-item' : ''
                        } `}
                        key={key}
                      >
                        <a
                          className={`uk-flex uk-flex-middle ${
                            disabled ? 'cursor-not-allowed' : 'default-cursor'
                          }`}
                        >
                          <div className="uk-flex uk-flex-middle column-gap-20 label-container flex-grow-1">
                            <img
                              // show a default (hidden) image when default disaster item
                              src={
                                defaultDisasterLocation
                                  ? disaster_type === disasterType.FIRE
                                    ? iconFire
                                    : iconAccident
                                  : iconMap[key]
                              }
                              width="60"
                              height="60"
                              alt={name}
                              style={{
                                visibility: defaultDisasterLocation
                                  ? 'hidden'
                                  : 'inherit',
                              }}
                            />
                            <label
                              htmlFor={nameForLabel}
                              className={`color-white font-25 flex-grow-1 ${
                                disabled
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            >
                              {name}
                            </label>
                          </div>
                          <div className="uk-margin-auto-left">
                            <input
                              className="uk-radio"
                              type="radio"
                              id={nameForLabel}
                              disabled={item.disabled}
                              readOnly
                              checked={item.selected}
                              onClick={() => {
                                dispatch({
                                  type: 'TOGGLE_REGISTER_ITEMS',
                                  payload: {
                                    item: key,
                                  },
                                })
                              }}
                            />
                          </div>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </li>

              {sandwichMenuState.registerSubMenuOpen ||
              sandwichMenuState.hideShowSubMenuOpen ? (
                <li>
                  <div className="uk-flex uk-flex-middle uk-flex-center column-gap-20">
                    <button
                      className="uk-button uk-btn-logout font-16 min-w-146"
                      onClick={closeMenu}
                    >
                      <Trans>BUTTONS.CANCEL</Trans>
                    </button>
                    <button
                      className="uk-button uk-btn-logout font-16 min-w-146"
                      disabled={!mapCoordinatesSuccess}
                      onClick={() => {
                        closeMenu()
                        dispatch({
                          type:
                            'SET_SELECTED_REGISTER_ITEMS_AND_SHOW_HIDE_ITEMS',
                          payload: {
                            hasDisaster,
                            hasMarker: hasMarker,
                          },
                        })

                        // refetch disaster items
                        queryClient.invalidateQueries({
                          queryKey: ['disaster-items', currentTenantId],
                        })

                        const atLeastOneSelected =
                          state.showHideItems.some((item) => item.selected) ||
                          state.registrationItems.some(
                            (item) =>
                              item.selected &&
                              item.key !== DEFAULT_DISASTER_LOCATION
                          )

                        // turn on toggle to show prevention items
                        // when submit button clicked
                        setPreventionItemsToggle?.(atLeastOneSelected)

                        // disable toggle if not at-least one of items selected
                        setPreventionItemsToggleDisabled?.(!atLeastOneSelected)

                        // set map zoom level to initial (when map was loaded)
                        const mapInitialZoom = mapInitialZoonRef.current
                        if (mapInitialZoom && mapObject) {
                          // mapObject.setZoom(mapInitialZoom)
                        }

                        // At last - show warning alert message on click of submit
                        // if zoom level not enough and do nothing
                        const mapZoom = getRoundedZoom(
                          mapObject?.getZoom() || 0
                        )
                        if (
                          mapZoom &&
                          mapZoom < minZoomForDoubleClick &&
                          atLeastOneSelected
                        ) {
                          setAlertMessage({
                            type: alertType.WARNING,
                            message: `${t(
                              'TITLES.PREVENTION_ITEMS_DISPLAY_ZOOM_LEVEL_ALERT'
                            )}`,
                          })
                          return
                        }
                      }}
                    >
                      <Trans>BUTTONS.APPLY</Trans>
                    </button>
                  </div>
                </li>
              ) : null}
            </>
          )}
          <li className="uk-margin-small-bottom">
            <a
              className="color-white font-23 selected-bg"
              onClick={handleQRCodePageRedirect}
            >
              <Trans>LABELS.QR_PAGE_MENU_ITEM</Trans>
            </a>
          </li>
        </ul>
      </div>
    </li>
  )
}

export default DisasterPreventionItemsControlMenu

function getPreventionItemsIcon({
  iconUrl,
  userRole,
  handleDelete,
}: {
  iconUrl: string
  userRole: string
  handleDelete: any
}) {
  const markerElement = document.createElement('div')
  markerElement.style.backgroundImage = iconUrl
  markerElement.style.backgroundSize = 'contain'
  markerElement.style.width = '43px'
  markerElement.style.height = '43px'
  markerElement.style.margin = '-12px -5px -8px 0px'
  // markerElement.style.zIndex = '1'
  const crossButton = document.createElement('div')
  crossButton.style.position = 'absolute'
  crossButton.style.top = '-3px'
  crossButton.style.right = '-2px'
  crossButton.style.width = '15px'
  crossButton.style.height = '15px'
  crossButton.style.backgroundImage = `url(${cancel})`
  crossButton.style.backgroundSize = 'cover'
  crossButton.style.backgroundPosition = 'center'
  crossButton.style.backgroundRepeat = 'no-repeat'
  crossButton.style.cursor = 'pointer'
  markerElement.appendChild(crossButton)
  crossButton.addEventListener('click', (e) => {
    handleDelete()
  })
  return markerElement
}

type SandwichMenuState = {
  wholeMenuOpen: boolean
  hideShowSubMenuOpen: boolean
  registerSubMenuOpen: boolean
}
function sandwichMenuReducer(
  curr: SandwichMenuState,
  next: Partial<SandwichMenuState>
): SandwichMenuState {
  return { ...curr, ...next }
}

function getRoundedZoom(value: number) {
  return +value.toFixed(1)
}
