import React, { useEffect, useReducer, useState } from 'react'

import NavigationHeader from '../../components/organisms/NavigationHeader'
import NavigationFooter, {
  filterReducer,
  initialEvacuationFilter,
  initialPartnerCompanySelection,
  initialUserSafetyFilter,
} from '../../components/organisms/NavigationFooter'
import NavigationSidebar from '../../components/organisms/NavigationSidebar'
import { Map } from './Map'
import UserSafetyListTable, { useTableAreaSize } from './UserSafetyListTable'
import { FetchUserSafetyListRes, UserTablePos } from '../../data/types/DisasterRiskManagement'
import type { Map as MapLibreMap } from 'maplibre-gl'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import NewSpinner from '../../components/common/NewSpinner'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { useCurrentRoute } from 'react-navi'
import { publicRoutesConstant } from '../../appConstants'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'

type Bounds = {
  top: number
  left: number
  right: number
  bottom: number
}

export const DEFAULT_POSITION = { x: 0, y: 0 }

const NewDashboard = () => {
  const [
    shouldSafetyListTableDisplay,
    setShouldSafetyListTableDisplay,
  ] = useState(false)
  const [preventionItemsToggle, setPreventionItemsToggle] = useState(false)
  const [isTableMinimize, setIsTableMinimize] = useState(true)
  const [tableData, setTableData] = useState<FetchUserSafetyListRes>([])
  const [mapObject, setMapObject] = useState<null | MapLibreMap>(null)
  const [
    preventionItemsToggleDisabled,
    setPreventionItemsToggleDisabled,
  ] = useState(true)

  const disasterStatusFetching = useIsFetching({
    queryKey: ['disaster-status'],
    exact: false,
  })
  const registerItemsFetching = useIsFetching({
    queryKey: ['disaster-register-items'],
    exact: false,
    stale: false, // do not show loader when invalidating
  })
  const disasterItemsFetching = useIsFetching({
    queryKey: ['disaster-items'],
    exact: false,
  })
  const userSafetyListFetching = useIsFetching({
    queryKey: ['user-safety-list'],
    exact: false,
  })

  const mapCoordinatesFetching = useIsFetching({
    queryKey: ['map'],
    exact: false,
  })

  const isMutating = useIsMutating({
    predicate(mutation) {
      return !mutation.options.mutationKey?.includes(
        'mutation-disaster-register-items'
      )
    },
  })

  const { fetchingUserInfo } = useAuth()

  const pathname = useCurrentRoute().url.pathname
  const publicFacingURL = publicRoutesConstant.includes(pathname)

  const parentRef = React.useRef<HTMLDivElement>(null)
  const tableRef = React.useRef<HTMLDivElement>(null)
  const headerRef = React.useRef<HTMLElement>(null)
  const sidebarRef = React.useRef<HTMLDivElement>(null)
  const footerRef = React.useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState<ReturnType<typeof getBounds>>()
  const [position, setPosition] = useState<UserTablePos>(
    // (0,0) is the mid position
    DEFAULT_POSITION
  )

  const [filterState, updateFilterState] = useReducer(filterReducer, {
    partnerListSelection: initialPartnerCompanySelection,
    evacuationListSelection: initialEvacuationFilter,
    safetyListSelection: initialUserSafetyFilter,
  })

  // Update position during drag
  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    setPosition({ x: data.x, y: data.y })
  }

  const tableAreaHeight = useTableAreaSize()
  const { width, height } = useWindowSize()

  // re calculate bounds on window size change
  useEffect(() => {
    const bounds_ = getBounds(!isTableMinimize)
    
    if (!(bounds_ && shouldSafetyListTableDisplay)) {
      return
    }
    setBounds(bounds_)
  }, [width, height, isTableMinimize, shouldSafetyListTableDisplay])

  return (
    <>
      {disasterStatusFetching > 0 ||
      registerItemsFetching > 0 ||
      disasterItemsFetching > 0 ||
      userSafetyListFetching > 0 ||
      mapCoordinatesFetching > 0 ||
      isMutating > 0 ||
      fetchingUserInfo ? (
        <NewSpinner />
      ) : null}
      {/* Navbar/Header */}
      {!publicFacingURL ? (
        <NavigationHeader
          headerRef={headerRef}
          mapObject={mapObject}
          preventionItemsToggle={preventionItemsToggle}
          setPreventionItemsToggle={setPreventionItemsToggle}
          preventionItemsToggleDisabled={preventionItemsToggleDisabled}
          setPreventionItemsToggleDisabled={setPreventionItemsToggleDisabled}
        />
      ) : null}
      {/* Sidebar */}
      <NavigationSidebar sidebarRef={sidebarRef} mapObject={mapObject} />

      {/* Map */}
      <div className="uk-flex uk-map uk-position-relative"
        style={{ height: `${tableAreaHeight}px` }}
      >
        <Map
          tableData={tableData}
          setTableData={setTableData}
          mapObject={mapObject}
          setMapObject={setMapObject}
        />
        {/* Safety List Table */}
        <div className="table-parent" ref={parentRef}
          // on maximizing, set higher z-index so that table comes over footer
          // in case some part would be hidden (below footer)
          // commenting for now, since it hides footer dropdowns
          // style={{ zIndex: isTableMinimize ? undefined : '10000' }}
          data-pos-x={position.x}
          data-pos-y={position.y}
        >
          <Draggable
            disabled={!isTableMinimize} // Disable dragging when maximized
            position={isTableMinimize ? position : DEFAULT_POSITION} // reset position to default when maximized
            bounds={bounds}
            onDrag={handleDrag}
          >
            <div>
              <UserSafetyListTable
                tableRef={tableRef}
                shouldSafetyListTableDisplay={shouldSafetyListTableDisplay}
                setShouldSafetyListTableDisplay={
                  setShouldSafetyListTableDisplay
                }
                isTableMinimize={isTableMinimize}
                setIsTableMinimize={setIsTableMinimize}
                filterState={filterState}
                setPosition={setPosition}
                bounds={bounds}
              />
            </div>
          </Draggable>
        </div>
      </div>

      {/* Footer */}
      <NavigationFooter
        footerRef={footerRef}
        mapObject={mapObject}
        setShouldSafetyListTableDisplay={setShouldSafetyListTableDisplay}
        shouldSafetyListTableDisplay={shouldSafetyListTableDisplay}
        preventionItemsToggle={preventionItemsToggle}
        setPreventionItemsToggle={setPreventionItemsToggle}
        preventionItemsToggleDisabled={preventionItemsToggleDisabled}
        filterState={filterState}
        updateFilterState={updateFilterState}
        setBounds={setBounds}
        isTableMinimize={isTableMinimize}
        setPosition={setPosition}
        position={position}
      />
    </>
  )
}

export default NewDashboard

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,
  })

  React.useEffect(() => {
    const handleResizeOrZoom = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio, // Detect zoom level
      })
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResizeOrZoom)

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResizeOrZoom)
    }
  }, [])

  return windowSize;
}

export function getBounds(maximized: boolean): Bounds | undefined {
  const tableParent = document.querySelector('.table-parent');
  const tableArea = document.querySelector('.table-area');
  const header = document.querySelector('.header')
  const sideMenu = document.querySelector('.side-menu')
  const footer = document.querySelector('.footer')

  if (!(tableParent && tableArea && header && sideMenu && footer)) {
    return;
  }

    const {
      height: parentHeight,
      x: parentX,
      width: parentWidth,
      right: tableParentRight
    } = tableParent.getBoundingClientRect()

    const {
      width: tableWidth, height: tableHeight,
      x: tableAreaX,
      top: tableAreaTop,
      right: tableAreaRight,
      bottom: tableAreaBottom,
      left: tableAreaLeft,
    } = tableArea.getBoundingClientRect()

    const {
      height: headerHeight,
      bottom: headerBottom
    } = header.getBoundingClientRect()

    const {
      width: sidebarWidth,
      right: sidebarRight,
    } = sideMenu.getBoundingClientRect()

    const {
      height: footerHeight,
      top: footerTop
    } = footer.getBoundingClientRect()

    // a lil margin around the area
    const MARGIN_AROUND = 10

    const minTop    =   headerBottom - tableAreaTop + (maximized ? 0 : MARGIN_AROUND)
    const right = tableAreaRight - tableParentRight + (maximized ? 0 : MARGIN_AROUND)
    const minLeft   = tableAreaLeft - sidebarRight - (maximized ? 0 : MARGIN_AROUND);
    const maxBottom =  -tableAreaBottom + footerTop - (maximized ? 0 : MARGIN_AROUND)

    if (maximized) {
      return {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }
    }

    const positionX = +(tableParent.getAttribute('data-pos-x') ?? 0)
    const positionY = +(tableParent.getAttribute('data-pos-y') ?? 0)

    // return bounds
    // (relative to current position of user table)
    const calculatedBounds = {
      top: minTop + positionY,
      left: -minLeft + positionX,
      right: -right + positionX,
      bottom: maxBottom + positionY,
    }
    return calculatedBounds
}